import React from 'react'

import styles from './project.module.css'

import { PortfolioList } from '../list/PortfolioList'
import { IPortfolio } from '../../../graphql/IPortfolio'
import { Video } from '../../video/Video'
import { Markdown } from '../../markdown/Markdown'

interface Props {
  project: IPortfolio
}

export const Project: React.FC<Props> = ({ project }) => {
  const {
    title,
    description,
    credits,
    year,
    embed_video,
    is_self_hosted_video,
    self_hosted_video
  } = project.node.frontmatter

  return (
    <>
      <div className={styles.container}>
        <div className={styles.video}>
          <Video
            title={title}
            isSelfHostedVideo={is_self_hosted_video}
            embedVideo={embed_video}
            selfHostedVideo={self_hosted_video}
          />
        </div>
        <h2 className={styles.title}>{title}</h2>
        {description ? (
          <div className={styles.description}>
            <Markdown content={description} />
          </div>
        ) : null}
        {credits ? (
          <div className={styles.credits}>
            <Markdown content={credits} />
          </div>
        ) : null}
        {year ? <p className={styles.year}>{year}</p> : null}
      </div>

      <div className={styles.moreProjectsContainer}>
        <h5 className={styles.moreProjectsText}>More Projects</h5>
        <PortfolioList />
      </div>
    </>
  )
}
