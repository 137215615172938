import React from 'react'
import { WindowLocation } from '@reach/router'

import Layout from '../components/layout/Layout'
import { SEO } from '../components/SEO'
import { Project } from '../components/portfolio/project/Project'
import { usePortfolioData } from '../graphql/usePortfolioData'
import { extractFileName } from '../helpers/extractFileName'
import { removeSlashes } from '../helpers/removeSlashes'

interface Props {
  location: WindowLocation
}

const PortfolioTemplate: React.FC<Props> = ({ location }) => {
  const portfolioItems = usePortfolioData()

  const url = location.pathname ?? ''
  const slug = extractFileName(removeSlashes(url))

  const project = portfolioItems.filter(item => item.node.fields.slug === slug)[0]

  if (!project) return null

  const { title, description } = project.node.frontmatter

  return (
    <>
      <Layout>
        <SEO title={title} description={description} />
        <Project project={project} />
      </Layout>
    </>
  )
}

export default PortfolioTemplate
